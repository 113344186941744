import { createContext, useContext } from 'react'
import { StoreApi, createStore, useStore } from 'zustand'

type AppSidebarStore = {
  sidebarState: 'open' | 'closed'
  actions: {
    toggleSidebar: () => void
    openSidebar: () => void
    closeSidebar: () => void
  }
}

export const initializeAppSidebarStore = (shouldDefaultClosed: boolean) => {
  return createStore<AppSidebarStore>((set) => ({
    sidebarState: shouldDefaultClosed ? 'closed' : 'open',
    actions: {
      toggleSidebar: () =>
        set((state) => ({ sidebarState: state.sidebarState === 'open' ? 'closed' : 'open' })),
      openSidebar: () => set({ sidebarState: 'open' }),
      closeSidebar: () => set({ sidebarState: 'closed' }),
    },
  }))
}

export const AppSidebarContext = createContext<StoreApi<AppSidebarStore>>({} as any)

export function useAppSidebarStore<T>(
  selector: (state: AppSidebarStore) => T,
  equalityFn?: (left: T, right: T) => boolean
) {
  const store = useContext(AppSidebarContext)
  if (!store) throw new Error('useAppSidebarStore must be used within a AppSidebarContext.Provider')
  return useStore(store, selector, equalityFn)
}
